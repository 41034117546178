

function enumValueToLabel(value, targetEnum) {
  let entry = targetEnum.find(item => item.value === value);
  if (entry) {
    return entry.label;
  }
  if (process.env.VUE_APP_BUILD_ENV === 'development') {
    return '没找到该项';
  }
}

export default enumValueToLabel;
