<template>
  <input ref="input" :disabled="disabled"  :type="inputType" :style="inputStyle"
         @blur="handleBlur" @focus="handleFocus" @keyup.enter="handleEnter"
         v-bind="inputProps" @input="handleInput"  :maxlength="maxlength"/>
</template>
<script>
export default {
  props: {
    inputProps: {
      type: Object,
      default: () => ({}),
    },
    maxlength: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
    },
    inputStyle: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleInput(e) {
      const val = e.target.value;
      this.$emit('input', val);
    },
    handleEnter() {
      this.$emit('enter');
    },
    handleFocus() {
      this.$emit('focus');
    },
    handleBlur() {
      this.$emit('blur');
    },
  },
};
</script>
