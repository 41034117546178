<template>
  <div class="form-item">
    <span class="error-tip">
      {{errorTip}}
    </span>
    <slot name="prepend"/>
    <input
      class="form-item-input"
      :type="type"
      :placeholder="placeholder"
      @input="handleInput"
      autocomplete="off"
    >
    <slot
      name="append"
    ></slot>
  </div>
</template>

<script>
export default {
  props: {
    errorTip: String,
    type: String,
    value: String,
    placeholder: String,
  },

  data() {
    return {

    };
  },

  methods: {
    handleInput(e) {
      const { value } = e.target;
      this.$emit('input', value);
    },
  },
};
</script>


<style lang="scss" scoped>
.form-item {
  border-bottom: 1px solid rgba(138,151,166,0.5);
  height: 40px;
  line-height: 40px;
  position: relative;
  margin-top: 6px;
  padding-top: 12px;

  & .form-item-input {
    border: 0;
    outline: none;
    font-size: 14px;
    color: rgba(24,30,38,1);
    width: 220px;
    background: transparent;
    padding: 0;
    height: 40px;
    vertical-align: bottom;
  }

  & .form-item-append {
    color: rgba(104,113,220,1);
    font-size: 12px;
    float: right;
    cursor: pointer;
  }

  & .error-tip {
    position: absolute;
    top: 4px;
    left: 0px;
    color: rgba(225,106,130,1);
    font-size: 12px;
    line-height: 12px;
  }
}
</style>
