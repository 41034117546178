
const staticEnums = {
  // 币种
  currencyType: [
    {
      value: 1,
      label: '人民币',
    },
    {
      value: 2,
      label: '美元',
    },
  ],
};

export default staticEnums;
