import Vue from 'vue';
import Vuex from 'vuex';
import qs from 'qs';
import config from '@/config';

import * as loginApi from '@/api/login';
import enums from './modules/enums';
import user from './modules/user';
import geeTest from './modules/geeTest';
import qrCode from './modules/qrCode';
import view from './modules/view';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    view,
    enums,
    user,
    geeTest,
    qrCode,
  },
  state: {
    inited: false,
  },
  mutations: {
    SET_INITED(state) {
      state.inited = true;
    },
  },
  actions: {
    /**
     * 重定向到ok_url
     */
    RedirectTo() {
      const query = qs.parse(window.location.search.slice(1));
      const redirect = query.ok_url || config.defaultRedirect;
      window.location.href = decodeURIComponent(redirect);
    },

    /**
     * 判断是否登录
     */
    async CheckLogin({ dispatch, commit }) {
      const res = await loginApi.checkLogin();
      if (res.success) {
        dispatch('RedirectTo');
      }
      commit('SET_INITED');
      return res;
    },
  },
  getters,
  strict: false,
});
