export default {
  namespaced: true,
  state: {
    ratio: 1,
    scrollTop: document.body.scrollTop,
    componentScrollTop: {},
    bdListData: {},
  },
  mutations: {
    refreshScrollTop(state) {
      state.scrollTop = document.body.scrollTop;
    },
    addComponentScrollTop(state, nv) {
      state.componentScrollTop = Object.assign({}, state.componentScrollTop, nv);
    },
    addBdListData(state, data) {
      state.bdListData = Object.assign({}, state.bdListData, data);
    },
  },
  actions: {
  },
  getters: {

  },
};
