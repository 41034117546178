/* eslint-disable */
import axios from 'axios';
import qs from 'qs';
import clonedeep from 'lodash.clonedeep';
import pathToRegexp from 'path-to-regexp';

/**
 * 请求成功状态码
 */
export const SUCCESS_BIZ_CODE = 0;

/**
 * 不同的空格编码方式
 * @see https://github.com/ljharb/qs#rfc-3986-and-rfc-1738-space-encoding
 */
// const FORMAT_ENCODE = 'RFC3986';

/**
 * 包含请求体的请求类型
 */
const WITH_BODY_REQUESTS = ['put', 'post', 'patch'];

class Http {
  constructor({
    urlencoded = false,
    ...axiosOptions
  } = {}) {
    this.options = {
      urlencoded,
    };

    this.axiosInstance = axios.create(axiosOptions);
  }
  /**
   * 这个地方很坑，后台有各种格式响应数据，需要做兼容处理。。
   * @param {*} options
   */
  request(options) {
    return this.fetch(Object.assign({}, this.options, options))
      .then((response) => {
        const { status: statusCode, statusText, data: resData } = response;
        if (typeof resData.code === 'number') {
          return {
            success: resData.code === 0,
            message: resData.msg || statusText,
            statusCode,
            bizCode: resData.code,
            data: resData.data ,
          }
        }
        return {
          success: true,
          message: resData.message || statusText,
          statusCode,
          bizCode: resData.code,
          data: resData,
        };
      })
      .catch((error) => {
        const { response } = error;
        let resData = null;
        if (response && response instanceof Object) {
          resData = response.data;
          if (resData.code) {
            return {
              success: false,
              message: resData.msg || resData.message || response.statusText,
              statusCode: response.status,
              bizCode: resData.code,
              data: resData.data,
            }
          }

          return {
            success: false,
            message: resData.message,
            statusCode: response.status,
            bizCode: response.status,
            data: resData,
          };

        }

        return {
          success: false,
          message: error.message,
          statusCode: 600,
          bizCode: 600,
          error,
        };
      });
  }

  fetch(options) {
    let {
      url,
      method = 'get',
      data,
      urlencoded,
      ...restOptions
    } = options;

    method = method.toLowerCase();

    /**
     * 使用clone对象，避免直接修改传入data
     */
    if (!(data instanceof FormData)) {
      data = clonedeep(data);
    }

    /**
     * 拼接路径参数，将路径参数在data中删除
     */
    try {
      let domin = '';
      if (url.match(/[a-zA-z]+:\/\/[^/]*/)) {
        [domin] = url.match(/[a-zA-z]+:\/\/[^/]*/);
        url = url.slice(domin.length);
      }
      const match = pathToRegexp.parse(url);
      url = pathToRegexp.compile(url)(data);
      for (let i = 0; i < match.length; i++) {
        const item = match[i];
        if (item instanceof Object && item.name in data) {
          delete data[item.name];
        }
      }
      url = domin + url;
    } catch (e) {
      throw new Error(e.message);
    }
    /**
     * 请求参数对象
     */
    const requestOptions = {
      url,
      method,
      ...restOptions,
    };

    if (WITH_BODY_REQUESTS.includes(method)) {
      requestOptions.data = data;
    } else {
      requestOptions.params = data;
    }

    /**
     * 当请求格式为application/x-www-form-urlencoded时需要将请求体格式化为URLSearchParams
     * @see https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
     */
    if (urlencoded) {
      requestOptions.transformRequest = [(requestData) => {
        if (!(requestData instanceof FormData)) {
          return qs.stringify(requestData);
        }
        return requestData;
      }];
      requestOptions.paramsSerializer = params => qs.stringify(params);
    }

    return this.axiosInstance(requestOptions);
  }
}

export default Http;
