var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "input",
    _vm._b(
      {
        ref: "input",
        style: _vm.inputStyle,
        attrs: {
          disabled: _vm.disabled,
          type: _vm.inputType,
          maxlength: _vm.maxlength
        },
        on: {
          blur: _vm.handleBlur,
          focus: _vm.handleFocus,
          keyup: function($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.handleEnter($event)
          },
          input: _vm.handleInput
        }
      },
      "input",
      _vm.inputProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }