
class CDown {
  constructor({
    duration = 60,
    interval = 1,
    onProgress = () => {},
  } = {}) {
    this.timerId;
    this.duration = duration;
    this.interval = interval;
    this.onProgress = onProgress;
    this.current = duration;
  }

  start() {
    this.reset();
    this.onProgress.call(this, 'start', this.current);
    const run = () => {
      this.timerId = setTimeout(() => {
        this.current -= 1;
        if (this.current > 0) {
          this.onProgress.call(this, 'running', this.current);
          run();
        } else {
          this.onProgress.call(this, 'end', this.current);
        }
      }, this.interval * 1000)
    }
    run();
  }

  reset() {
    clearTimeout(this.timerId);
    this.current = this.duration;
  }
}


export default CDown;
