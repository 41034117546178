import * as qrcodeApi from '@/api/qrcode';
import * as loginApi from '@/api/login';
import config from '@/config';

// const NO_SCAN = 0;
// const NO_LOGIN = 1;
const LOGIN = 2;
const EXPIRED = 3;

let loopId = 0;

const qrCode = {
  namespaced: true,

  state: {
    expired: false,
    token: '',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },

    SET_EXPIRED: (state, expired) => {
      state.expired = expired;
    },
  },

  actions: {
    /**
     * 获取token
     * @param {*} param0
     * @param {*} params
     */
    async RefreshToken({ commit }) {
      const res = await qrcodeApi.getToken();
      const { success, data } = res;
      if (success) {
        commit('SET_EXPIRED', false);
        commit('SET_TOKEN', data.token);
      }
      return res;
    },

    async LoopToken({ dispatch, commit, state }) {
      loopId = setInterval(async () => {
        const { success, data } = await qrcodeApi.getTokenStatus({
          token: state.token,
        });

        if (success) {
          if (data.status === LOGIN) {
            const setCookieRes = await loginApi.setCookie();
            if (setCookieRes.success) {
              dispatch('RedirectTo', null, {
                root: true,
              });
            }
          }
          if (data.status === EXPIRED) {
            commit('SET_EXPIRED', true);
          }
        }
      }, config.loopInterval);
    },

    async ClearLoopToken() {
      clearInterval(loopId);
    },
  },
};

export default qrCode;
