import * as loginApi from '@/api/login';

const geeTest = {
  namespaced: true,

  state: {
    visible: false,
  },

  mutations: {
    SET_VISIBLE: (state, visible) => {
      state.visible = visible;
    },
  },

  actions: {
    /**
     * 现实滑动验证码
     * @param {*} param0
     * @param {*} params
     */
    async ShowGeeTest({ commit }) {
      const res = await loginApi.geeTestInit();
      const { success, data } = res;
      if (success) {
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line
          initGeetest({
            gt: data.gt,
            challenge: data.challenge,
            product: 'embed', // 产品形式，包括：float，popup
            // offline: !data.success, // 表示用户后台检测极验服务器是否宕机
            // new_captcha: true, // 用于宕机时表示是新验证码的宕机
          }, (captchaObj) => {
            commit('SET_VISIBLE', true);
            captchaObj.appendTo('#__GEE_TEST__');
            captchaObj.onSuccess(() => {
              const result = captchaObj.getValidate();
              commit('SET_VISIBLE', false);
              resolve(result);
            });
            captchaObj.onError((e) => {
              reject(e);
            });
          });
        });
      }
      return res;
    },
  },
};

export default geeTest;
