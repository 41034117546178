module.exports = {
  setCookieUrl: 'https://rong.jingdata.com',
  qrCodeUrl: 'https://rong.jingdata.com/m/#/msgToApp',
  defaultRedirect: 'https://rong.jingdata.com',
  loopInterval: 2000,

  sentry: {
    dsn: 'https://7c8f28f0ab0f4ccf876b60305b7d1d63@sentry.jingdata.com/16',
  },
};
