/* eslint-disable */
/**
 * 不同环境载入不同配置文件
 * @todo 按需加载配置文件
 */

let config = {};

if (process.env.BUILD_ENV === 'development') {
  config = require('./config.dev');
} else if (process.env.BUILD_ENV === 'test') {
  config = require('./config.test');
} else if (process.env.BUILD_ENV === 'sim') {
  config = require('./config.sim');
} else {
  config = require('./config.prod');
}

export default config;
