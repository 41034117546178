import Http from './http';
import config from '@/config';
import checkLoginInterceptor from './checkLoginInterceptor';

const defaultHttp = new Http({
  baseURL: config.defaultServer,
  timeout: 10000,
  urlencoded: true,
});

defaultHttp.axiosInstance.interceptors.response.use(checkLoginInterceptor);

const defaultRequest = defaultHttp.request.bind(defaultHttp);

export {
  defaultRequest as default,
  defaultHttp,
};
