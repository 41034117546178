import mapValues from 'lodash.mapvalues';

// 异步枚举获取配置
const enumsConfig = {
  dictCC: {
    url: '/dict/cc',
    init: true,
    responseIntercepter: data => data.map(item => ({
      cc: item.cc,
      code: item.code,
      desc: item.desc,
    })),
  },
};

const asyncEnums = mapValues(enumsConfig, () => []);

export {
  asyncEnums as default,
  enumsConfig,
};
