import * as Sentry from '@sentry/browser';
import config from '@/config';
import { defaultHttp } from './request';

const { sentry: sentryConfig } = config;

/**
 * 初始化raven
 */
Sentry.init({
  dsn: sentryConfig.dsn,
});

function useSentryInterceptor(axios) {
  // // eslint-disable-next-line
  axios.interceptors.request.use(cfg => cfg, (error) => {
    Sentry.withScope((scope) => {
      scope.setExtra('request', error.request);
      scope.setExtra('config', error.config);
      scope.setTag('ajax', 'request');
      Sentry.captureException(error);
    });
    return Promise.reject(error);
  });
  axios.interceptors.response.use(response => response, (error) => {
    Sentry.withScope((scope) => {
      scope.setExtra('response', error.response);
      scope.setExtra('config', error.config);
      scope.setTag('ajax', 'response');
      Sentry.captureException(error);
    });
    return Promise.reject(error);
  });
}

useSentryInterceptor(defaultHttp.axiosInstance);
