const NOT_LOGIN_CODE = 4011;

function checkLoginInterceptor(res) {
  const { data } = res;
  if (data && data.code === NOT_LOGIN_CODE) {
    console.error('您没有登录，请登录后重试')
  }
  return res;
}

export default checkLoginInterceptor;
