var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-item" },
    [
      _c("span", { staticClass: "error-tip" }, [
        _vm._v("\n    " + _vm._s(_vm.errorTip) + "\n  ")
      ]),
      _vm._v(" "),
      _vm._t("prepend"),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-item-input",
        attrs: {
          type: _vm.type,
          placeholder: _vm.placeholder,
          autocomplete: "off"
        },
        on: { input: _vm.handleInput }
      }),
      _vm._v(" "),
      _vm._t("append")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }