import * as loginApi from '@/api/login';
import { removeToken } from '@/common/auth';

const NEED_TEST_CODE = 4032;

const user = {
  namespaced: true,

  state: {
    name: '',
    uid: '',
  },

  mutations: {
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_UID: (state, uid) => {
      state.uid = uid;
    },
    CLEAR_UID: (state) => {
      state.name = '';
      state.uid = '';
    },
  },

  actions: {
    // 获取用户信息
    async GetUserInfo({ commit }, state) {
      const res = await loginApi.getUserInfo({
        uid: state.uid,
      });
      if (res.success) {
        const { data } = res;
        commit('SET_NAME', data.userName);
        commit('SET_UID', data.uid);
      }
      return res;
    },

    // 登出
    async LogOut({ commit }, returnTo) {
      const res = await loginApi.logout({
        return_to: returnTo,
      });
      if (res.success) {
        removeToken();
        commit('CLEAR_UID');
      }
      return res;
    },

    // 登录或注册
    async Login({ dispatch }, params) {
      const loginRes = await loginApi.loginOrRegister(params);
      if (loginRes.success) {
        const setCookieRes = await loginApi.checkLogin();
        if (setCookieRes.success) {
          setTimeout(() => {
            dispatch('RedirectTo', null, { root: true });
          }, 2000);
        }
        return setCookieRes;
      }
      // 判断是否需要验证码
      if (NEED_TEST_CODE === loginRes.bizCode) {
        loginRes.needGeeTest = true;
      }
      return loginRes;
    },

    // 验证验证码
    async VerifyCode(store, params) {
      const res = await loginApi.verifyCode(params);
      return res;
    },

    // 手机号快捷登录的发送验证码
    async SendCode(store, params) {
      const res = await loginApi.sendCode(params);
      return res;
    },

    // 重置密码的发送验证码
    async SendForgetSms(store, params) {
      const res = await loginApi.resetPassword(params);
      return res;
    },

    // 设置密码
    async SetPassword(store, params) {
      const res = await loginApi.setPassword({
        passwd: params.passwd,
      });
      return res;
    },
  },
};

export default user;
