<template>
  <button
    class="submit-btn"
    @click="handleClick"
    :disabled="disabled"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  methods: {
    handleClick(...args) {
      this.$emit('click', ...args)
    },
  },
}
</script>




<style lang="scss" scoped>
.submit-btn {
  margin-top: 5px;
  background: #5A5D84;
  font-size: 12px;
  color: #fff;
  text-align: center;
  border: 0;
  line-height: 36px;
  width: 100%;
  outline: 0;
  cursor: pointer;
  &:disabled {
    opacity: .7;
  }
  &:hover {
    background: darken(#5A5D84, 10%)
  }
}
</style>
