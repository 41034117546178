import request from '@/common/request';

/**
 * 获取二维码状态
 * @see http://192.168.2.9:8080/workspace/myWorkspace.do?projectId=119#2447
 * @param token {string}  token
 *
 * @return msg {string}
 * @return data {object}
 * @return code {number}
 */
export function getTokenStatus(params) {
  return request({
    // v2: true,
    url: '/passport/qrcode/token/status',
    method: 'GET',
    data: {
      token: params.token,
    },
  });
}

/**
 * 获取token接口
 * @see http://192.168.2.9:8080/workspace/myWorkspace.do?projectId=119#2446
 *
 * @return msg {string}
 * @return data {object}
 * @return code {number}
 */
export function getToken() {
  return request({
    // v2: true,
    url: '/passport/qrcode/token/obtain',
    method: 'GET',
  });
}
